<template>

  <v-dialog
    width="900"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center align-start headline flex-column mb-6">
        <span>Request to Join the <span class="font-weight-black">{{ client.name }} organization account</span></span>
      </v-card-title>

      <v-card-text v-if="!hasBeenSent">
        <v-row>
            <v-col cols="12">
                <v-row>
                  <v-col cols=12>
                    <p>Send a request to the administrator for the {{ client.name }} account asking to be added as a user to that account. You will be notified as soon as the administrator acts on your request.</p>
                    <p>Please only request to join accounts of organizations for which you are a staff member or otherwise should have the ability to assist in preparing that organization's meetings.</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols=12>
                    <h4>It is best to include a personal message with your request to join that helps identify you as the correct person to add to the account. Enter any information below to assist the administrator in identifying you and considering your request.</h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols=12>
                    <v-textarea
                    outlined
                    :rules="rules.messageRules"
                    :counter="maxMessageLength"
                    v-model="requestMessage">
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols=12
                  style="text-align:center;">
                    <v-btn
                    color="primary"
                    @click="sendRequest"
                    >
                  <v-progress-circular
                    v-if="saving===true"
                    indeterminate
                    size="16"
                    class="mr-3"
                    ></v-progress-circular>
                  {{ buttonLabel}}</v-btn>
                  </v-col>
                </v-row>
            </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <v-row
          v-if="requestSuccess">
            <v-col cols="12">
                <v-row>
                  <v-col cols=12>
                    <p>Thank you. Your request has been sent to the administrator of the {{client.name}} account. You will be notified when your request is acted upon.</p>
                  </v-col>
                </v-row>
                <v-row>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-row>
                  <v-col
                    cols=12
                    style="text-align:center">
                    <v-icon
                      color="accentGreen"
                      size="100">
                      mdi-send-check-outline</v-icon>
                  </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row
          v-else>
            <v-col cols=12>
              {{requestFailMessage}}
            </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false" class="mr-2">Close</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'
export default Vue.extend({
  props: {
    value: {
      required: true,
      type: Boolean
    },
    client: {}
  },
  data () {
    return {
      saving: false,
      maxMessageLength: 250,
      requestMessage: '',
      rules: {
        messageRules: [
          v => (v || '').length <= this.maxMessageLength || 'Message must have fewer than ' + this.maxMessageLength + ' characters'
        ]
      },
      buttonLabel: 'Send request to join',
      hasBeenSent: false,
      requestSuccess: true,
      requestFailMessage: ''
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    sendRequest () {
      this.saving = true
      this.buttonLabel = 'Sending request'

      const requestOptions = {
        message: this.requestMessage,
        clientId: this.client.id
      }

      axios.post(process.env.VUE_APP_BACKEND_URL + '/api/join-request/', requestOptions)
        .then((response) => {
          this.saving = false
          this.hasBeenSent = true
          if (!response.data.success) {
            this.requestSuccess = false
            this.requestFailMessage = response.data.message
          }
        })
    }
  },
  watch: {
    show (value) {
      if (value === true) {
        // this.$emit('reload', true)
      } else {
        this.hasBeenSent = false
      }
    },
    hasBeenSent (value) {
      if (!value) {
        this.buttonLabel = 'Send request to join'
        this.requestSuccess = true
        this.requestMessage = ''
      }
    }
  }
})
</script>
