<template>
  <div>
    <v-row
        v-if="!embedded"
        style="background-color: #ECEFF1;">
      <v-col>
        <v-row>
          <v-spacer></v-spacer>
          <v-col>
            <v-img height="150" contain src="@/assets/agendease-logo-name-below-transparent.png"></v-img>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col>
        <h2>Find your organization</h2>
        <p>To send a request to your organization so that you can be added to its account as a user, you must first find it in the list below.</p><p>Begin displaying the list by entering search text in the field below. You can further filter the list by entering the {{this.subdivisionLabel.toLowerCase()}} in which it is located.</p>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col>
        <v-text-field
        label="Organization Name"
        outlined
        clearable
        v-model="enteredSearchText"
        @input="searchTextChanged"></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col>
        <v-select
        label="Country"
        :items="countries"
        v-model="selectedCountry"
        clearable
        v-on:change="countryChanged"></v-select>
      </v-col>
      <v-col>
        <v-select
        :label="subdivisionLabel"
        :items="subdivisions"
        :disabled="subdivisionDisabled"
        v-model="selectedSubdivision"
        clearable
        @change="subdivisionChanged"></v-select>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <div
      v-if="organizationResults == ''">
    <v-row>
      <v-spacer></v-spacer>
      <v-col>
      <v-alert
        text
        outlined
        color="info"
        icon="mdi-account-group-outline"
      >
        {{standardErrorMessage}}
      </v-alert>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row
      v-if="displayAddMyOrganizationButton">
      <v-spacer></v-spacer>
      <v-col
      style="text-align:center;">
          <v-btn
          color=info
          class="elevation-10"
          large>I want my organization on Agendease</v-btn>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    </div>
    <div
      v-else>
      <v-row
        v-if="atMaxListSize">
        <v-spacer></v-spacer>
        <v-col>
          <v-alert
          text
          outlined
          color="primary"
          icon="mdi-text-search"
        >
          Showing the first ten results of your search. Refine your search to show more results.
          </v-alert>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col>
          <v-container>
            <v-list
            v-for="result in organizationResults"
            v-bind:key="result.id"
            style="background-color: transparent;"
            light
            max-width="900">
              <v-list-item
              class="my-1"
              style="border: solid 2px black;background-color: white;border-radius: 10px;"
              v-if="noAssociation(result.id)"
              >
                <v-list-item-avatar>
                  <v-img
                    :src="pathToClientAvatars + result.logo"
                  >
                  </v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{result.name}}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{result.city}}, {{result.state}}
                  </v-list-item-subtitle>
                </v-list-item-content>
              <v-list-item-action>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                  <v-btn icon
                    v-on="on"
                    @click="requestToJoin(result)">
                    <v-icon color="accentGreen lighten-1" x-large>mdi-send-circle</v-icon>
                  </v-btn>
                  </template>
                  <span>
                    Send a request to join the {{result.name}} organization account
                  </span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
            <v-list-item
              v-if="alreadyAMember(result.id)">
                <v-list-item-icon>
                  <v-icon
                  color=positive
                  large>mdi-check-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                <v-list-item-title>
                  You are already a member of the {{result.name}} organization account
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{result.city}}, {{result.state}}
                </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="alreadyPending(result.id)">
                  <v-list-item-icon>
                    <v-icon
                    color=grey
                    large>mdi-timer-sand</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                  <v-list-item-title>
                    {{result.name}}, ({{result.city}}, {{result.state}})
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Your request to join the {{result.name}} organization account is already pending.<br>Please contact the account administrator if you have questions regarding your request.
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                    <v-btn icon
                      v-on="on"
                      @click="cancelRequestToJoin(result)">
                      <v-icon color="accentRed lighten-1" x-large>mdi-close-circle</v-icon>
                    </v-btn>
                    </template>
                    <span>
                      Cancel your request to join the {{result.name}} organization account
                    </span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                v-if="alreadyPendingAuth(result.id)">
                  <v-list-item-icon>
                    <v-icon
                    color=orange
                    large>mdi-alert</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                  <v-list-item-title>
                    {{result.name}}, ({{result.city}}, {{result.state}})
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    The administrator for the {{result.name}} organization account has requested additional information from you<br>before the request can be approved. Please check your email for a message regarding the request, and contact the<br>organization if you have questions.
                  </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                      <v-btn icon
                        v-on="on"
                        @click="cancelRequestToJoin(result)">
                        <v-icon color="accentRed lighten-1" x-large>mdi-close-circle</v-icon>
                      </v-btn>
                      </template>
                      <span>
                        Cancel your request to join the {{result.name}} organization account
                      </span>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-container>
          </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </div>

    <request-to-join v-model="modals.requestToJoin" :client="clientToJoin" :clientName="clientToJoin.name"></request-to-join>
    <cancel-request-to-join v-model="modals.cancelRequestToJoin" :client="clientToJoin" ></cancel-request-to-join>
  </div>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'
import localities from '@/assets/general-settings/localities.json'
import axios from 'axios'
import RequestToJoin from '@/components/LandingPages/RequestToJoin.vue'
import CancelRequestToJoin from '@/components/LandingPages/CancelRequestToJoin.vue'

export default Vue.extend({
  name: 'JoinOrganization',
  components: {
    RequestToJoin,
    CancelRequestToJoin
  },
  props: {
    embedded: Boolean
  },
  data: () => ({
    modals: {
      requestToJoin: false,
      cancelRequestToJoin: false
    },
    subdivisionLabel: 'State',
    countries: localities.countries,
    subdivisions: [],
    subdivisionDisabled: true,
    selectedCountry: null,
    selectedSubdivision: null,
    enteredSearchText: null,
    activeOrganizations: {},
    organizationResults: [],
    listMaxSize: 10,
    atMaxListSize: false,
    pathToClientAvatars: process.env.VUE_APP_IMAGES_S3_BUCKET + '/' + process.env.VUE_APP_IMAGES_S3_FOLDER_ORGANIZATION_AVATARS + '/',
    messageType: 'standard',
    displayAddMyOrganizationButton: false,
    userOrganizationsData: {},
    userOrganizationsDataPending: {},
    userOrganizationsDataPendingAuth: {},
    clientToJoin: {
      name: ''
    }
  }),
  computed: {
    collectionToSearch () {
      if ((this.enteredSearchText === null || this.enteredSearchText === '') && this.selectedCountry === null && this.selectedSubdivision === null) {
        return []
      }

      if (this.selectedCountry !== null) {
        if (!this.activeOrganizations.bySubdivision[this.selectedCountry]) {
          return []
        }

        if (this.selectedSubdivision !== null) {
          if (!this.activeOrganizations.bySubdivision[this.selectedCountry].subdivisions[this.selectedSubdivision]) {
            return []
          }

          return this.activeOrganizations.bySubdivision[this.selectedCountry].subdivisions[this.selectedSubdivision]
        } else {
          return this.activeOrganizations.bySubdivision[this.selectedCountry].clients
        }
      } else {
        return this.activeOrganizations.all
      }
    },
    standardErrorMessage () {
      switch (this.messageType) {
        case 'noResults':
          return 'There are no results for the search text that you entered.'
        case 'noCountry':
          return 'There are no organizations using Agendease in this country yet.'
        case 'noSubdivision':
          return 'There are no organizations using Agendease in this ' + this.subdivisionLabel.toLowerCase() + ' yet. Let your organization be the first.'
        case 'standard':
        default:
          return 'Enter search text or choose a ' + this.subdivisionLabel.toLowerCase() + ' to display a list of organizations to choose from.'
      }
    },
    userOrganizations () {
      const organizationIds = []
      this.userOrganizationsData.forEach(element => {
        organizationIds.push(element.clientID)
      })

      return organizationIds
    },
    userOrganizationsPending () {
      const organizationIds = []
      this.userOrganizationsDataPending.forEach(element => {
        organizationIds.push(element.client_id)
      })

      return organizationIds
    },
    userOrganizationsPendingAuth () {
      const organizationIds = []
      this.userOrganizationsDataPendingAuth.forEach(element => {
        organizationIds.push(element.client_id)
      })

      return organizationIds
    }
  },
  watch: {
    collectionToSearch () {
      this.displayAddMyOrganizationButton = false
      if ((this.enteredSearchText === null || this.enteredSearchText === '') && this.selectedCountry === null && this.selectedSubdivision === null) {
        this.messageType = 'standard'
      } else if (this.enteredSearchText !== '') {
        this.displayAddMyOrganizationButton = true
        this.messageType = 'noResults'
      }

      if (this.selectedCountry !== null) {
        if (!this.activeOrganizations.bySubdivision[this.selectedCountry]) {
          this.displayAddMyOrganizationButton = true
          this.messageType = 'noCountry'
          return
        }
      }

      if (this.selectedSubdivision !== null) {
        if (!this.activeOrganizations.bySubdivision[this.selectedCountry].subdivisions[this.selectedSubdivision]) {
          this.displayAddMyOrganizationButton = true
          this.messageType = 'noSubdivision'
          return
        }
      }

      this.messageType = 'noResults'
    }
  },
  methods: {
    getAllOrganizations () {
      axios.get(process.env.VUE_APP_BACKEND_URL + '/api/clients/active-organizations')
        .then((response) => {
          this.activeOrganizations = response.data
          this.userOrganizationsData = response.data.userOrganizations.member
          this.userOrganizationsDataPending = response.data.userOrganizations.pending
          this.userOrganizationsDataPendingAuth = response.data.userOrganizations.pendingAuth
        }).catch(function (error) {
          console.log(error)
        })
    },
    searchList () {
      const searchTextRegEx = (this.enteredSearchText ? this.enteredSearchText.toLowerCase() : '')
      this.atMaxListSize = false

      this.organizationResults = []
      let listSize = 0
      if (this.collectionToSearch) {
        this.collectionToSearch.forEach(element => {
          if (listSize === this.listMaxSize) {
            this.atMaxListSize = true
            return false
          }

          if (element.name.toLowerCase().search(searchTextRegEx) !== -1) {
            this.organizationResults.push(element)
            listSize++
          } else {
          }
        })
      }
    },
    searchTextChanged () {
      this.searchList()
    },
    subdivisionChanged () {
      this.searchList()
    },
    countryChanged () {
      if (this.selectedCountry === null) {
        this.selectedSubdivision = null
        this.subdivisionDisabled = true
      } else {
        this.subdivisionLabel = localities.subdivisions[this.selectedCountry].subdivisionName
        this.subdivisions = localities.subdivisions[this.selectedCountry].subdivisions
        this.subdivisionDisabled = false
      }

      this.searchList()
    },
    requestToJoin (clientToJoin) {
      this.clientToJoin = clientToJoin
      this.modals.requestToJoin = true
    },
    cancelRequestToJoin (clientToJoin) {
      this.clientToJoin = clientToJoin
      this.modals.cancelRequestToJoin = true
    },
    alreadyAMember (clientId) {
      return this.userOrganizations.indexOf(clientId) !== -1
    },
    alreadyPending (clientId) {
      return this.userOrganizationsPending.indexOf(clientId) !== -1
    },
    alreadyPendingAuth (clientId) {
      return this.userOrganizationsPendingAuth.indexOf(clientId) !== -1
    },
    noAssociation (clientId) {
      if (!this.alreadyAMember(clientId) && !this.alreadyPending(clientId) && !this.alreadyPendingAuth(clientId)) {
        return true
      }
    }
  },
  beforeMount () {
    this.getAllOrganizations()
  }
})
</script>
